<template>
  <div ref="button" class="ho-vket-booth-marche-button" :class="color">
    <template v-if="disabled">
      <div class="link">
        <slot />
        <ArrowWhite class="arrow" />
      </div>
    </template>
    <template v-else>
      <HaLink class="link" :to="to" :blank="blank">
        <slot />
        <ArrowWhite class="arrow" />
      </HaLink>
    </template>
  </div>
</template>

<script setup lang="ts">
import ArrowWhite from '@/assets/images/vket_booth_marche/arrow-white.svg'

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    color?:
      | 'orange'
      | 'discord'
      | 'green'
      | 'yellow'
      | 'red'
      | 'white'
      | 'border-orange'
      | 'gray'
    to?: string
    blank?: boolean
  }>(),
  {
    disabled: false,
    color: 'orange',
    to: '',
    blank: false,
  }
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const button = ref<Element | null>(null)

onMounted(() => {
  nextTick(() => {
    const observer = new IntersectionObserver((elements) => {
      elements.forEach((element) => {
        if (element.isIntersecting) {
          element.target.classList.add('-is-display')
        } else {
          element.target.classList.remove('-is-display')
        }
      })
    })

    if (button.value === null) {
      throw new Error('button is null')
    }
    observer.observe(button.value)
  })
})

function onClick(): boolean | void {
  if (props.disabled) return false
  return emit('click')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-vket-booth-marche-button {
  border: 4px solid;
  border-radius: 8px;
  filter: drop-shadow(8px 8px 0 v.$gray-9);
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 24px;
  font-weight: 800;
  max-width: 320px;
  position: relative;
  text-align: center;
  transition: 0.3s;
  width: 100%;
  @include m.sp {
    font-size: 16px;
    max-width: 240px;
  }

  &.-is-display {
    animation-duration: 0.8s;
    animation-name: popUp;
  }

  &:hover {
    filter: none;
    transform: translate(8px, 8px);
  }

  &.orange {
    background: v.$orange-1;
    border-color: v.$gray-9;

    > .link {
      color: v.$white;
    }
  }

  &.discord {
    background: v.$discord;
    border-color: v.$gray-9;

    > .link {
      color: v.$white;
    }
  }

  &.green {
    background: v.$green-5;
    border-color: v.$gray-9;

    > .link {
      color: v.$white;
    }
  }

  &.yellow {
    background: v.$yellow-2;
    border-color: v.$gray-9;

    > .link {
      color: v.$gray-9;
    }

    :deep(path) {
      fill: v.$gray-9;
    }
  }

  &.red {
    background: v.$red-2;
    border-color: v.$gray-9;

    > .link {
      color: v.$yellow-2;
    }

    :deep(path) {
      fill: v.$yellow-2;
    }
  }

  &.white {
    background: v.$white;
    border-color: v.$gray-9;

    > .link {
      color: v.$gray-9;
    }

    :deep(path) {
      fill: v.$gray-9;
    }
  }

  &.border-orange {
    background: v.$yellow-2;
    border-color: v.$red-2;

    > .link {
      color: v.$red-2;
    }

    :deep(path) {
      fill: v.$red-2;
    }
  }

  &.gray {
    background: v.$gray-5;
    border-color: v.$gray-9;

    > .link {
      color: v.$gray-9;
    }

    :deep(path) {
      fill: v.$gray-9;
    }

    &:hover {
      filter: drop-shadow(8px 8px 0 v.$gray-9);
      transform: unset;
    }
  }

  > .link {
    display: block;
    height: 100%;
    padding: 8px 16px 8px 8px;
    white-space: break-spaces;
    width: 100%;
  }

  > .link > .arrow {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@keyframes popUp {
  0% {
    filter: none;
    transform: translate(8px, 8px);
  }

  60% {
    filter: none;
    transform: translate(8px, 8px);
  }

  100% {
    filter: drop-shadow(8px 8px 0 v.$gray-9);
  }
}
</style>
